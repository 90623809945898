
.login-container {
    height: 100vh !important;
    flex-direction: unset !important;
    align-items: center;
    background-image: url("../../assets/images/login-bg.jpg");
    background-size: 100% 100%;
}

.login-avatar {
    margin-bottom: 30px;
}
