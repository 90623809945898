
.me-user-info {
    padding: 30px 0px;
    background-color: var(--adm-color-background);
}

.me-user-info-username {
    margin-top: 5px;
    font-size: 16px;
    color: #666666;
}

.me-user-info-nickname {
    font-weight: bold;
}