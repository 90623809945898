
.property-loglist-memo {
    padding-bottom: 3px;
    font-size: 16px;
}

.property-loglist-amount {
    font-size: 18px;
    text-align: right;
    color: black;
}

.property-loglist-after {
    font-size: var(--adm-font-size-main);
    text-align: right;
}