
.secret-details-title {
    padding-top: 16px;
    text-align: center;
}

.secret-details-title h3,
.secret-details-title p {
    margin: 5px 15px;
}

.secret-details-title h3 {
    font-size: 18px;
}

.secret-details-title p {
    color: var(--adm-color-weak);
}

.secret-details-content {
    padding: 15px;
    font-size: 16px;
}