
.unlock-container {
    height: 100vh !important;
    flex-direction: unset !important;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/images/login-bg.jpg");
    background-size: 100% 100%;
}

.unlock-btn-body {
    margin-top: 30px;
}

.unlock-btn-logout {
    font-size: 16px;
    color: #666666;
}
