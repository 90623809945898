
.property-historydetails-net-assets {
    padding: 25px 0px;
    background-color: var(--adm-color-background);
    text-align: center;
}

.property-historydetails-net-assets p {
    margin: 0;
}

.property-historydetails-net-assets-amount {
    font-size: 30px;
}

.property-historydetails-net-assets-name {
    padding-top: 3px;
    color: var(--adm-color-weak);
    font-size: 16px;
}

.property-historydetails-list-amount {
    padding: 4px 0;
    color: #333333;
    font-size: var(--font-size);
}

.property-historydetails-list-amount span {
    float: right;
}