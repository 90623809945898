
.property-historylist-memo {
    font-size: 18px;
    color: #333333;
}

.property-historylist-amount {
    display: flex;
    margin-top: 8px;
}

.property-historylist-amount div {
    flex: 1;
}

.property-historylist-amount span {
    display: block;
    padding: 2px 0px;
    text-align: center;
}

.property-historylist-amount div span:first-child {
    color: var(--adm-color-weak);
    font-size: 16px;
}

.property-historylist-amount div span:last-child {
    font-size: 20px;
    color: black;
}
