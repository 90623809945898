body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-body {
  flex: 1;
  overflow-y: auto;
}

.app-bottom {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.page-body {
  flex: 1;
  overflow-y: auto;
}

.page-bg {
  background-color: #f6f6f6;
}